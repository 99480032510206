import React from 'react';

import useStyles from '../../style/Table/OrderArrows';

import filterArrow from '../../../public/icon-arrow-order.svg';
import filterArrowSelected from '../../../public/icon-arrow-order-selected.svg';

interface Props {
  headCell: any,
  order: string,
  orderBy: string,
  setOrder(value: string): void,
  setOrderBy(value: string): void,
}

/*
 * Component that renders the arrows for ordering a table.
 */
const OrderArrows: React.FC<Props> = ({
  headCell,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const classes = useStyles();

  /*
   * Updates the order of the table.
   */
  const handleOrder = (order: string): void => {
    if (orderBy === headCell.value) setOrder(order)
    else setOrderBy(headCell.value);
  };

  return (
    <div className={classes.container}>
      {headCell.name}

      {
        headCell.name !== '' &&
        <div className={classes.arrowContainer}>
        {
          (order === 'ASC' && headCell.value === orderBy && headCell.value !== 'value') ||
          (order === 'DESC' && headCell.value === orderBy && headCell.value === 'value') ?
          <img
            src={filterArrowSelected}
            alt=""
            className={classes.upperArrow}
            onClick={() => handleOrder('ASC')}
            style={order === 'ASC' ? { fill: 'black' } : {}}
          /> :
          <img
            src={filterArrow}
            alt=""
            className={classes.upperArrow}
            onClick={() => handleOrder('ASC')}
            style={order === 'ASC' ? { fill: 'black' } : {}}
          />
        }

        {
          (order === 'DESC' && headCell.value === orderBy && headCell.value !== 'value') ||
          (order === 'ASC' && headCell.value === orderBy && headCell.value === 'value') ?
          <img
            src={filterArrowSelected}
            alt=""
            onClick={() => handleOrder('DESC')}
            style={order === 'DESC' ? { fill: 'black' } : {}}
          /> :
          <img
            src={filterArrow}
            alt=""
            onClick={() => handleOrder('DESC')}
            style={order === 'DESC' ? { fill: 'black' } : {}}
          />
        }
        </div>
      }
    </div>
  );
};

export default OrderArrows;
