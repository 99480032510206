/*
 * Supported values per page of the table.
 */
export const availableRowsPerPage = ['10', '50', '100'];

/*
 * Heatmap colors for the table.
 * Fixed to 10 values.
 * For reference, check the function `parseResultForHeatMap` in `backend/src/utils/parser.ts`
 */
export const heatmapColours = [
  '#D9DFED',
  '#C4CFED',
  '#ACBDEA',
  '#91ABF0',
  '#7E97DA',
  '#5D7DD3',
  '#4C65A7',
  '#2647A0',
  '#19347A',
  '#041952'
];
