import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Filter} from '../app/interfaces/Filter';

export interface FiltersState {
    [key: string]: Array<Filter>, // Generic to avoid problems with the extra reducer typing.
    active: Array<Filter>,
    available: Array<Filter>, // Although this property shouldn't contain `Filter` elements, the defined structure is the same.
}

/*
 * Initial state of slice.
 *
 * `àctive` stores the current selected filters.
 * `general` stores the default available filters.
 *
 * Any other property stores just the catalog of that specific filter.
 */
const initialState: FiltersState = {
    active: [],
    available: [],
};

/*
 * Thunk to fetch the filters catalogs.
 *
 * This is expected to be dispatched the first time the page loads.
 */
export const fetchFilterCatalogs = createAsyncThunk(
    'filters/catalog',
    async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/catalog/filters`)
            return await res.json();
        } catch (error) {
            console.error('Error while fetching the filters catalog', error);
        }
    }
);

export const fetchAllFilters = createAsyncThunk('filters/load', async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dimensions`);
        return await response.json()
    } catch (error) {
        console.error('Error while fetching the filters', error);
    }
})

/*
 * Initial config of the slice.
 *
 * There is one dispatchable reducer called `ùpdate`.
 * This reducer updates the current selected (active) filters.
 *
 * There is another extra reducer, non dispatchable.
 * This extra reducer is executed once the `fetchFilterCatalogs` thunk is executed and fulfilled.
 * It basically extracts the keys of the catalog filters and by sets it to the current state given that key.
 */
export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Array<any>>) => {
            state.active = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFilterCatalogs.fulfilled, (state, action) => {
            Object.entries(action.payload || {})
                .forEach((filter: Array<any>) => {
                    const [key, values] = filter;
                    state[key] = values;
                })
        }).addCase(fetchAllFilters.fulfilled, (state, action) => {
            for (const dimension of action.payload) {
                state.available.push({name: dimension.description, value: dimension.name})
            }
        });
    }
});

/*
 * Updates the current selected (active) filters.
 */
export const {update} = filtersSlice.actions;

/*
 * Reducers of the filters slice.
 */
export default filtersSlice.reducer;
