import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  margin: { margin: theme.spacing(1) },

  add: {
    color: '#21366E',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: { fontSize: 14 },
  },

  delete: {
    color: '#868686',
    cursor: 'pointer',
    fontSize: 16,
    position: 'absolute',
    right: 5,
    top: 0,
    [theme.breakpoints.down('md')]: { fontSize: 14 },
  },

  addDisabled: {
    color: 'rgba(49, 76, 148, 0.35)',
    cursor: 'not-allowed',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: { fontSize: 14 },
  },

  dimensions: {
    alignSelf: 'center',
    color: '#21366E',
    fontSize: 14,
    fontWeight: 700,
  },

  select: {
    border: '1px solid #21366E',
    borderRadius: 8,
    color: '#21366E',
    fontSize: 13.5,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: { fontSize: 12 },
  },

  selectCircle: {
    borderRadius: '100%',
    height: 10,
    width: 10,
  },

  selects: { 
    gap: '1rem',
    height: '100%',
    justifyContent: 'center'
  },
}));

export default useStyles;
