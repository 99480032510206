import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Abbreviations } from "../app/interfaces/Abbreviations";

interface AbbreviationsState { data: Abbreviations }

/*
 * Initial state of slice.
 *
 * This should be updated whenever it fetch the data with the latest constraints
 * to verify if it includes any abbreviation.
 */
const initialState: AbbreviationsState = { data: {} };

/*
 * Initial config of slice.
 *
 * It includes a single reducer where it updated the current abbreviations based
 * on the fetched data with the latest constraitnts.
 */
export const abbreviationsSlice = createSlice({
  name: 'abbreviations',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Abbreviations>) => {
      state.data = action.payload;
    },
  },
});

/*
 * Updates the current abbreviations.
 */
export const { update } = abbreviationsSlice.actions;

/*
 * Reducers of the slice.
 */
export default abbreviationsSlice.reducer;
