import { makeStyles, Theme } from '@material-ui/core';

export const useActionStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    marginBottom: 40,
    justifyContent: 'center',
  },

  button: {
    backgroundColor: '#314C94',
    border: 'none',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 700,
    padding: '10px 50px',
    [theme.breakpoints.down('sm')]: { fontSize: 11 },

    '&:hover': {
      backgroundColor: '#263E7D',
      cursor: 'pointer',
    },
  },
}));
