import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  margin: { margin: theme.spacing(1) },

  title: {
    alignSelf: 'center',
    color: '#21366E',
    fontSize: 15,
    fontWeight: 700,
  },

  container: {
    borderRadius: 8,
    color: '#21366E',
    fontWeight: 700,
    margin: '0 0 15px 0',
    padding: 0,
    width: '100%',
    '& > div': { maxWidth: '100%' },
  },

  input: {
    border: '1px solid #21366E',
    borderRadius: '10px!important' as any,
    fontSize: 13,
    '&:hover': { borderColor: '#8E8E8E!important' as any },
    '&focus': { boxShadow: 'none' },
    '&:focus-within': {
      boxShadow: 'none!important' as any,
      borderColor: '#8E8E8E!important' as any,
    },
    '& input': {
      fontSize: 13,
      fontWeight: 700,
    },
    '& input::placeholder': { color:'#4D5C85' },
  },
}));

export default useStyles;
