import { heatmapColours } from '../constants/table';

import IconDepth1 from '../../public/icon-info-panel-1.svg';
import IconDepth2 from '../../public/icon-info-panel-2.svg';
import IconDepth3 from '../../public/icon-info-panel-3.svg';
import IconDepth4 from '../../public/icon-info-panel-4.svg';

/*
 * Loads an arrow-like icon based on the depth of the dimension.
 *
 * This is intended to be used in the `<Chart>` component and it supports only up to a depth of 4.
 */
export const loadDepthIcon = (depth: number): string | undefined => {
  switch (depth) {
    case 1:
      return IconDepth1;
    case 2:
      return IconDepth2;
    case 3:
      return IconDepth3;
    case 4:
      return IconDepth4;
    default:
      return undefined;
  };
};

/*
 * Sets a color based on a depth of a dimension.
 *
 * It only supports up to a depth of 4.
 */
export const setDepthColour = (depth: number): string => {
  switch (depth) {
    case 1:
      return '#314C94';
    case 2:
      return '#EA2B2F';
    case 3:
      return '#87A1E7';
    case 4:
      return '#124877';
    default:
      return '#ccc';
  };
};

export const setHeatmapColour = (index: number): string => {
  return heatmapColours[index] || '#FFFFFF';
};
