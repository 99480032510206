import React, {ChangeEvent} from 'react';
import {Grid, InputAdornment, MenuItem, TextField} from '@material-ui/core';

import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../../store';

import {update} from '../../../slices/dimensionsSlice';

import useStyles from '../../style/General/DimensionSelector';

import DimensionsIcon from '../../../public/dimensions-icon.png';

import {Dimension} from '../../interfaces/Dimension';
import {setDepthColour} from '../../utils/depth';

const EMPTY_DIMENSION = {name:"", value: ""}

interface Props {
}

/*
 * Component of the selector of the dimensions and the selected dimensions used in the `<Chart>` component.
 */
const DimensionSelector: React.FC<Props> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const dimensionsToSearch = useSelector((state: RootState) => state.dimension.data);
    const allDimensions = useSelector((state: RootState) => state.dimension.dimensions);

    /*
     * Updates the state of the selected dimensions.
     *
     * This is used when a new dimension was added and it selected a dimension or when a dimension is just updated.
     */
    const selectNewDimension = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, depth: number): void => {
        const {value} = e.target;
        const condition = allDimensions.filter(d => d.value === value)[0] || EMPTY_DIMENSION;
        const currentDepth = dimensionsToSearch.length;

        if (depth === currentDepth) {
            let newDimensionsToSearch = [...dimensionsToSearch];

            newDimensionsToSearch[depth - 1] = condition;
            dispatch(update([...newDimensionsToSearch]));

        } else if (depth <= currentDepth) {
            let newDimensionsToSearch = dimensionsToSearch;

            if (currentDepth - depth > 1) {
                newDimensionsToSearch.splice(depth, currentDepth);

                newDimensionsToSearch[depth - 1] = condition;
            } else {
                newDimensionsToSearch.splice(depth - 1, currentDepth - 1, condition)
            }

            dispatch(update([...newDimensionsToSearch]));
        } else {
            dispatch(update([...dimensionsToSearch, condition]));
        }
    };

    /*
     * Adds a new dimension empty to the store.
     */
    const addNewDimension = (): void => {
        if (dimensionsToSearch.length < 4 && !emptyConditionExists()) dispatch(update([...dimensionsToSearch, EMPTY_DIMENSION]));
    };

    /*
     * Deletes a selected dimension.
     */
    const deleteDimension = (index: number): void => {
        let newDimensionsToSearch = [...dimensionsToSearch];

        newDimensionsToSearch.splice(index, 1);

        dispatch(update([...newDimensionsToSearch]));
    };

    /*
     * Checks if there is an empty dimension among the dimensions.
     */
    const emptyConditionExists = (): boolean => {
        return dimensionsToSearch.filter(c => c.value === '' && c.name === '').length > 0 ? true : false;
    };

    /*
     * Checks if a dimension has been already selected.
     */
    const isDimensionSelected = (dimension: Dimension): boolean => {
        const {name, value} = dimension;

        return dimensionsToSearch.filter((c: Dimension) => c.name === name && c.value === value).length > 0 ? true : false;
    };

    return (
        <Grid container direction="column" className={classes.selects}>
            <Grid item container justify="center" style={{gap: '2rem'}}>
                <Grid item className={classes.dimensions}>
                    <img src={DimensionsIcon} alt=""/> Dimensions:
                </Grid>

                {
                    dimensionsToSearch.map((condition, i) => {
                        return (
                            <Grid item key={`dimensionsToSearch-${i}`}>
                                <TextField select variant="outlined" disabled={i !== (dimensionsToSearch.length - 1)}
                                           value={condition.value} onChange={(e) => selectNewDimension(e, i + 1)}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <div className={classes.selectCircle}
                                                            style={{backgroundColor: setDepthColour(i + 1)}}></div>
                                                   </InputAdornment>
                                               ),
                                               endAdornment: (
                                                   dimensionsToSearch.length > 1 && <div className={classes.delete}
                                                                                         onClick={() => deleteDimension(i)}>x</div>
                                               ),
                                               className: classes.select
                                           }}
                                >

                                    {
                                        allDimensions.map(d =>
                                            <MenuItem
                                                key={`${d.value}-${i}`}
                                                value={d.value}
                                                selected={condition.value === d.value}
                                                disabled={condition.value === d.value || isDimensionSelected(d)}>
                                                {d.name}
                                            </MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>
                        )
                    })
                }
            </Grid>

            <Grid item
                  className={(dimensionsToSearch.length < 4 && !emptyConditionExists()) ? classes.add : classes.addDisabled}>
                <div onClick={(_) => addNewDimension()}>+ Add new dimension</div>
            </Grid>
        </Grid>
    );
};

export default DimensionSelector;
