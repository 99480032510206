import React, { useState } from 'react';

import useStyles from '../../style/Layout/index';

import FilterComponent from './FilterContainer';
import Chart from '../Chart';
import Table from '../Table/index';
import DimensionSelector from '../General/DimensionSelector';

import { CssBaseline, Grid } from '@material-ui/core';

interface Props {}

/*
 * Component of the main layout of the app.
 *
 * This includes the filter component, the title and the navigator between the chart and the table.
 */
const Layout: React.FC<Props> = () => {
  const classes = useStyles();

  const [dashboard, setDashboard] = useState<number>(0);

  /*
   * Navigates between the table and the chart.
   *
   * There are only 2 available options, any other option will return an empty div tag.
   */
  const renderDashboard = () => {
    switch(dashboard) {
      case 0:
        return <Chart />;
      case 1:
        return <Table />
      default:
        return <div />
    }
  };

  return (
    <Grid container wrap="nowrap">
      <CssBaseline />
      <Grid item xs={3} md={2} style={{minWidth: 190}}>
        <FilterComponent />
      </Grid>

      <Grid  container item xs={9} md={10} direction="column"
        wrap="nowrap" alignItems="stretch" spacing={4}>
        <Grid item xs={1} style={{minWidth: '100%'}}> 
          <div className={classes.title}></div>
          <div className={classes.options}>
            <div className={classes.item} style={dashboard === 0 ? { borderBottom: '4px solid #EA2B2F' } : { fontWeight: 300 }} onClick={() => setDashboard(0)}>Sunburst Chart View</div>
           <div className={classes.item} style={dashboard === 1 ? { borderBottom: '4px solid #EA2B2F' } : { fontWeight: 300 }} onClick={() => setDashboard(1)}>Table View</div>
        </div>
        </Grid>
        <Grid item xs={8} style={{minWidth: '100%'}}>
          <div className={classes.dashboardContainer}>{ renderDashboard() }</div>
        </Grid>
        <Grid item xs={3} lg={2} style={{minWidth: '100%'}}><DimensionSelector /></Grid>
      </Grid>
    </Grid>
  )
};

export default Layout;
