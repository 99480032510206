import * as d3 from "d3";
import {NestedData} from "../interfaces/data";
import {setDepthColour} from "./depth";
import {HierarchyNode} from "d3";
import months from "../constants/months";

/*
 * Defined width for the chart.
 */
export const WIDTH: number = 480;

/*
 * Radius based on the defined width of the chart.
 */
export const RADIUS: number = WIDTH / 2;

/*
 * Set low opacity in non hovered dimensions.
 */
export const setLowOpacity = (nodeRef: HTMLDivElement): void => {
    d3.select(nodeRef)
        .selectAll('path')
        .style('opacity', 0.52);
}

/*
 * Set high opacity in selected dimensions, even in the children when selecting a depth dimension is hovered.
 */
export const setHighOpacity = (nodeRef: HTMLDivElement, ancestors: unknown[] = []): void => {
    const selection = d3.select(nodeRef)
        .selectAll('path');

    ancestors.length > 0 ? selection.filter(node => ancestors.indexOf(node) >= 0).style('opacity', 1) : selection.style('opacity', 1);
};

/*
 * Draw the label in the center of a slice using the centroid value.
 */
export const drawLabel = (d: d3.HierarchyRectangularNode<NestedData>): string => `translate(${arc.centroid(d)})`;

/*
 * Just an instance of the defined arc.
 */
export const arc = d3.arc<d3.HierarchyRectangularNode<NestedData>>()
    .startAngle(d => d.x0)
    .endAngle(d => d.x1)
    .padAngle(_d => 1)
    .padRadius((_d: any) => 1)
    .innerRadius(d => Math.sqrt(d.y0) + 1)
    .outerRadius(d => Math.sqrt(d.y1) - 2);

/*
 * Draw representation of the arc.
 */
export const drawArc = (data: any): string | null => arc(data);

const customOrder = ['CR', 'PR', 'SD', 'PD', 'NE', 'Yes', 'No', '0-18', '18-30', '31-40', '41-50', '51-60', '61-70',
    '71-80', '81-90', '91+'];

function sortOrder(b: HierarchyNode<NestedData>, a: HierarchyNode<NestedData>) {
    if (customOrder.includes(a.data.name)) {
        return customOrder.indexOf(a.data.name) - customOrder.indexOf(b.data.name);
    }
    return b.value! - a.value!;
}

/*
 * Partition of the data for a hierarchy structure. D3 based function.
 */
export const partition = (data: unknown): d3.HierarchyRectangularNode<NestedData> => {
    const root = (d3.hierarchy(data) as d3.HierarchyNode<NestedData>)
        .sum(d => d.value)
        .sort((a: d3.HierarchyNode<NestedData>, b: d3.HierarchyNode<NestedData>) => {
            return b.data.name != 'N/A' ? sortOrder(b, a) : -1;
        })

    return d3.partition()
        .size([2 * Math.PI, RADIUS * RADIUS])(root) as d3.HierarchyRectangularNode<NestedData>;
}

/*
 * Sets the font size based on the depth of the dimension.
 */
export const setFontSize = (d: d3.HierarchyRectangularNode<NestedData>): string => {
    // Arbitrary base font size.
    let fontSize = 14;
    const isFirstDepth = d.depth === 1;

    // Arbitrary font size reduction in the first depth.
    if (isFirstDepth) {
        fontSize -= 1.5;
    }

    // Arbitrary font size reduction in depths over one.
    if (d.depth > 1) {
        fontSize -= d.depth * 2;
    }

    return `${fontSize}px`;
}

/*
 * Formats label with its size and data.
 *
 * RNA is just taking in consideration for modifying the data (text) of the slice.
 *
 * All the label sizes are updated based on its depth.
 */
const formatLabel = (d: d3.HierarchyRectangularNode<NestedData>): string => {
    const RNAvalues = ['RNA present', 'RNA not present'];
    if (RNAvalues.includes(d.data.name)) {
        switch (d.data.name) {
            case RNAvalues[0]:
                return 'RNA +';
            case RNAvalues[1]:
                return 'RNA -';
            default:
                return '';
        }
    }

    // Default method use in ´drawLabel´ function.
    const [x, y] = arc.centroid(d);

    // Default values used in the `arc` function.
    const a = Math.sqrt(d.y0) + 1;
    const b = Math.sqrt(d.y1) - 2;

    // Trying to get the radius between the two limit arcs, a and b.
    const radius = (b - a) / 2;

    const textValue = d.data.name;
    // If it has any white space or the '/' char then the string should be divided.
    let [firstWord, hasSecondWord]: (string | undefined)[] = textValue.split(/\s|\//g).filter((v) => v);

    // If the value originally is N/A or if for some reason it couldn't be splitted then just set the original value.
    if (textValue === 'N/A' || !firstWord) {
        firstWord = textValue;
        hasSecondWord = undefined;
    }

    // Just an arbitrary magic number as a reference to limit how many chars can be rendered in a chart's slice.
    const baseLimit = 14;
    // Calculates the distance between a and b in a way to be compared with the size of the text.
    const limit = Math.sqrt(Math.pow(((x + radius) - (x - radius)), 2) + Math.pow((y + radius) - (y - radius), 2)) / baseLimit + (d.depth * 1);

    if (!hasSecondWord && firstWord.length <= limit) {
        return firstWord;
    }

    if (hasSecondWord && firstWord.length <= limit) {
        return `${firstWord}..`
    }

    return firstWord.substring(0, limit) + '.';
}

/*
 * Based on an arbitrary constant it validates if the label of a slice should be rendered.
 *
 * This is mainly used to avoid really small slices texts to be rendered.
 */
const shouldDisplayLabel = (d: d3.HierarchyRectangularNode<NestedData>): 'None' | null => {
    const {x0, x1, depth} = d;
    // Arbitrary value minimum limit.
    const baseLimit = 0.145;

    // 0.02 is an arbitrary value to subtract to make the value smaller when the depth increases.
    return x1 - x0 >= (baseLimit - (0.02 * depth)) ? null : 'None';
}


/*
 * Creates and updates the slices and labels based on the data extracted.
 *
 * TODO: there should be another strategy to update the rendered content but it's not so required.
 */
export const createChart = (nodeRef: HTMLDivElement, selectDimension: any, root: any, totalPatients: number): void => {
    const node = d3.select(nodeRef);

    const svg = node.select('svg').node() ? node.select('svg') : node.append('svg')
        .attr('viewBox', `-50, 0, ${WIDTH + 100}, ${WIDTH}`)
        .style('width', '100%')
        .style('height', 'auto');

    const g = svg.select('g').node() ? svg.select('g') : svg.append('g')
        .attr('transform', `translate(${WIDTH / 2}, ${WIDTH / 2})`);

    function filterNotApplicable() {
        return function (d: d3.HierarchyRectangularNode<NestedData>) {
            return d.depth == 1 || (d.data.name != 'N/A' && d.parent?.data.name != 'N/A');
        };
    }

    g.select('g').node() ?
        g.select('g')
            .selectAll('path')
            .remove().exit()
            .data(root.descendants().splice(1).filter(filterNotApplicable()))
            .enter().append('path')
            .attr('d', drawArc)
            .attr('fill', d => setDepthColour((d as d3.HierarchyRectangularNode<NestedData>).depth))
            .style('cursor', 'pointer')
            .on('mouseover', selectDimension)
        :
        g.append('g')
            .selectAll('path')
            .data(root.descendants().splice(1).filter(filterNotApplicable()))
            .enter().append('path')
            .attr('d', drawArc)
            .attr('fill', d => setDepthColour((d as d3.HierarchyRectangularNode<NestedData>).depth))
            .style('cursor', 'pointer')
            .on('mouseover', selectDimension)

    // Set the name of the dimensions
    g.select('#labels').node() ?
        g.select('#labels').attr('id', 'labels')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .style('user-select', 'none')
            .selectAll('text')
            .remove().exit()
            .data(root.descendants().splice(1).filter(filterNotApplicable()))
            .enter().append('text')
            .attr('display', d => shouldDisplayLabel(d as d3.HierarchyRectangularNode<NestedData>))
            .attr('fill', '#fff')
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .attr('transform', d => drawLabel(d as d3.HierarchyRectangularNode<NestedData>))
            .style('font-weight', 'bold')
            .style('font-size', d => setFontSize(d as d3.HierarchyRectangularNode<NestedData>)) // Should be dynamically set
            .text(d => formatLabel(d as d3.HierarchyRectangularNode<NestedData>))
        :
        g.append('g')
            .attr('id', 'labels')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .style('user-select', 'none')
            .selectAll('text')
            .data(root.descendants().splice(1).filter(filterNotApplicable()))
            .enter().append('text')

    g.select(".center-label").remove()
    let label = g.append("text")
        .attr("class", "center-label")
        .attr('text-anchor', 'middle')
        .attr("font-weight", "bold");

    const rawDate = new Date()
    label
        .append("tspan")
        .attr('text-anchor', 'middle')
        .attr("fill", "#EA2B2F")
        .attr("font-size", "1em")
        .attr('x', 0)
        .attr('y', 0)
        .attr('dy', '-3em')
        .text(`${months[rawDate.getMonth()]} ${rawDate.getUTCDate()}, ${rawDate.getFullYear()}`)
    label
        .append("tspan")
        .attr('text-anchor', 'middle')
        .attr("font-size", "3em")
        .attr("fill", "#314C94")
        .attr('x', 0)
        .attr('y', 0)
        .attr('dy', '0')
        .text(totalPatients)
    label
        .append("tspan")
        .attr("fill", "#EA2B2F")
        .attr("x", 0)
        .attr("y", 0)
        .attr("font-size", "1em")
        .attr('dy', '1.5em')
        .text("total patients")


};

