import React from 'react';
import { Grid } from '@material-ui/core';
import Select from 'react-dropdown-select';

import useStyles from '../../style/Filters/DropdownSelector';

interface Props {
  selectTitle?: string,
  dimensionsIcon?: string,
  entries: Array<any>,
  selectedValues?: string,
  setCurrentConditional?(data: string): void,
  setCurrentFilter?(data: string): void,
  setCurrentNames?(data: string): void,
}

/*
 * Component of a dropdown selector.
 *
 * This is used for the selection of a filter.
 */
const DropdownSelector: React.FC<Props> = ({
  selectTitle,
  dimensionsIcon,
  entries,
  selectedValues,
  setCurrentConditional,
  setCurrentFilter,
  setCurrentNames,
}) => {
  const classes = useStyles();
  const values = selectedValues ? [entries.find((entry) => entry.name === selectedValues)] : [];

  /*
   * Sets the current selected filters and the name/value of the available selectable filters.
   */
  const setFilters = (options: any): void => {

    if (options.length < 1) return;

    const selection = options[0].value;

    if (setCurrentConditional) setCurrentConditional(selection);
    if (setCurrentFilter) setCurrentFilter(selection);
    if (setCurrentNames) setCurrentNames(options[0].name);
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Grid container justify="center" style={{ width: '100%' }}>
            {
              selectTitle &&
              <Grid item className={classes.title}>
                <img src={dimensionsIcon} alt="" /> {selectTitle}:
              </Grid>
            }

            <Grid item style={{ width: '100%' }}>
              <Select
                className={classes.input}
                options={entries}
                placeholder="Select filter..."
                onChange={(values) => setFilters(values)}
                values={values}
                labelField="name"
                valueField="value"
              />
            </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default DropdownSelector;
