import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid #314C94',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    height: '75%',
    margin: 'auto',
    position: 'relative',
    minWidth: 160
  },

  header: {
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    padding: '25px 25px 0 25px',
  },

  title: {
    alignSelf: 'stretch',
    color: '#21366E',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '58px',
  },

  iconsContainer: { display: 'flex' },

  icon: { cursor: 'pointer' },

  filterContainer: {
    flexDirection: 'column',
    height: 500,
    marginBottom: 25,
    overflowY: 'scroll',
    width: '100%',
    '&::-webkit-scrollbar': { width: 0 },
  },

  add: {
    color: '#21366E',
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 700,
    padding: '0 25px 0 25px',
    marginBottom: 15,
    textAlign: 'center',
  },

  buttonContainer: {
    bottom: 0,
    position: 'absolute',
  },
}));

export default useStyles;
