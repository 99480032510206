import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      borderRadius: 10,
      padding: '1rem 1rem 1rem 2.5rem',
    },
  },

  closeButtonContainer: {
    padding: 0,
    textAlign: 'right',
    fontSize: 16,
  },

  closeButton: {
    color: '#314C94',
    cursor: 'pointer',
    fontWeight: 700,
  },

  title: {
    padding: '0 0 1rem 0',
    '& > h2': {
      fontSize: 18,
      [theme.breakpoints.down('md')]: { fontSize: 16 },
      [theme.breakpoints.down('sm')]: { fontSize: 14 },
    },
  },

  generalButtons: {
    padding: 0,
    '& > button': {
      fontWeight: 600,
      padding: '5px 15px',
    },
  },

  rejectButton: {
    fontSize: 12,
    backgroundColor: '#F1F1F1',
    '&:hover': { backgroundColor: '#CCCCCC' },
    [theme.breakpoints.down('sm')]: { fontSize: 10 },
  },

  acceptButton: {
    fontSize: 12,
    backgroundColor: '#314C94',
    color: '#FFFFFF',
    transition: 'none',
    '&:hover': { backgroundColor: '#3F51B5' },
    [theme.breakpoints.down('sm')]: { fontSize: 10 },
  },

  image: {
    height: 25,
    marginLeft: 7.5,
    width: 20,
  },
}));

export default useStyles;
