import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  margin: { margin: theme.spacing(1) },

  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    gap: '10%'
  },

  cardLegend: {
    margin: 'auto',
    width: '100%',
  },

  downloadButton: {
    fontSize: 13,
    color: '#263E7D',
    backgroundColor: '#F0F4F6',
    border: '0.5px solid #263E7D',
    borderRadius: 20,
    padding: 9,
    textTransform: 'none',
    marginRight: 10,
  },

  link: {
    cursor: "pointer"
  }

}));

export default useStyles;
