import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../../store';

import {replace} from '../../../slices/chartDataSlice';

import useStyles from '../../style/Table/index';

import Pagination from './Pagination';

import OrderArrows from './OrderArrows';

import {getDataOrdered} from '../../utils/data';
import {setDepthColour, setHeatmapColour} from '../../utils/depth';
import {availableRowsPerPage} from '../../constants/table';
import {TableDataResponse} from '../../interfaces/data';
import {update} from '../../../slices/abbreviationsSlice';
import {Button} from "@material-ui/core";
import DownloadIcon from "../../../public/download.svg";

interface Props {
}

/*
 * Component that renders the heat map table from the values extracted by the selected dimensions/filters.
 */
const HeatmapTable: React.FC<Props> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state: RootState) => state.chart.data);
    const totalEntries = useSelector((state: RootState) => state.chart.total);
    const dimensions = useSelector((state: RootState) => state.dimension.data);
    const filters = useSelector((state: RootState) => state.filters.active);
    const abbreviations = useSelector((state: RootState) => state.abbreviations.data);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<string>(availableRowsPerPage[0]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [order, setOrder] = useState<string>('ASC');
    const [orderBy, setOrderBy] = useState<string>('value');
    const [heatMapValues, setHeatMapValues] = useState<number[][]>(new Array(10).fill([]));

    // Rows of the table extracted from the data argument.
    const rows = data === undefined || data.children === undefined ? data : data.children;

    /*
     * Gets dimensions when ordering, changing the page or when a new dimension is selected.
     *
     * TODO: this needs to be a thunk in a slice store.
     */
    const getDimensions = useCallback(async (): Promise<void> => {
        try {
            // For Grant Total to work as intended, it is necessary to flip the current filtering direction
            const data = orderBy === 'value' ?
                await getDataOrdered(dimensions, filters, orderBy, order === 'ASC' ? 'DESC' : 'ASC', rowsPerPage, page + 1)
                :
                await getDataOrdered(dimensions, filters, orderBy, order, rowsPerPage, page + 1);
            const res: TableDataResponse = await data.json();

            dispatch(replace({data: res.data, total: res.total}));
            dispatch(update(res.abbreviations))

            setTotalPages(res.totalPages);
            setHeatMapValues(res.heatMap)
        } catch (error) {
            console.error(error);
        }
    }, [
        dimensions,
        dispatch,
        filters,
        order,
        orderBy,
        page,
        rowsPerPage
    ]);

    /*
     * Checks if the chosen dimensions contains any empty dimension.
     *
     * This empty dimension happens when the user adds a new dimension but no defined dimension has been chosen.
     */
    const emptyConditionExists = useCallback((): boolean => {
        return dimensions.filter(c => c.value === '' && c.name === '').length > 0 ? true : false;
    }, [dimensions]);

    useEffect(() => {
        if (!emptyConditionExists()) getDimensions();
    }, [emptyConditionExists, getDimensions]);

    /*
     * Replaces value if it has an abbrevation..
     */
    const parseName = (rowValue: string): string => {
        const isAbbreviations = abbreviations[rowValue];

        return isAbbreviations ? isAbbreviations : rowValue;
    };

    /*
      * Fetches and downloads the CSV report of the chosen dimensions.
      *
      * TODO: move this function into a thunk like function in a store slice.
      */
    const exportCSV = async (): Promise<void> => {
        const res = await getDataOrdered(dimensions, filters, orderBy, order, rowsPerPage, page, true);
        const blob = await res.blob();

        const file = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = file;
        a.setAttribute('download', 'table.csv');
        a.click();
        URL.revokeObjectURL(file);
    }

    return (
        <div className={classes.tableContainer}>
            <TableContainer className={classes.outerTable} component={Paper} style={{maxHeight: '60vh'}}>
                <Table className={classes.table} aria-label="sticky table" stickyHeader>
                    <TableHead>
                        <TableRow className={classes.cellFocus}>
                            {
                                dimensions.map((header: any, i: number) => {
                                    return (
                                        <TableCell component="th">
                                            <div style={{alignItems: 'center', display: 'flex', fontWeight: 600}}>
                                                <div className={classes.selectCircle}
                                                     style={{backgroundColor: setDepthColour(i + 1)}}/>

                                                <OrderArrows
                                                    headCell={header}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    setOrder={setOrder}
                                                    setOrderBy={setOrderBy}
                                                />
                                            </div>
                                        </TableCell>
                                    )
                                })
                            }

                            <TableCell component="th" align="center"
                                       style={{fontWeight: 800, textTransform: 'uppercase', width: 150}}>
                                <OrderArrows
                                    headCell={{name: 'Total', value: 'value'}}
                                    // For Grant Total to work as intended, it is necessary to flip the current filtering direction
                                    order={order === 'ASC' ? 'DESC' : 'ASC'}
                                    orderBy={orderBy}
                                    setOrder={setOrder}
                                    setOrderBy={setOrderBy}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            rows !== undefined ?
                                rows?.map((row: any) => (
                                    <TableRow key={row.name} className={classes.cellBorder}>
                                        {
                                            dimensions.map((dimension: any) => <TableCell component="th" scope="row">
                                                {
                                                    row[dimension.value] === '' ||
                                                    row[dimension.value] === null ?
                                                        'N/A' :
                                                        parseName(row[dimension.value])
                                                }
                                            </TableCell>)
                                        }

                                        <TableCell
                                            scope="row"
                                            align="right"
                                            className={classes.cellTotal}
                                            style={{
                                                backgroundColor: setHeatmapColour(heatMapValues.findIndex((v) => v.includes(row.value))),
                                                color: heatMapValues.findIndex((v) => v.includes(row.value)) >= 5 ? '#FFFFFF' : '#000000'
                                            }}
                                        >{row.value}</TableCell>
                                    </TableRow>
                                )) :
                                <TableRow/>
                        }
                    </TableBody>

                    <TableFooter style={{bottom: 0, position: 'sticky'}}>
                        <TableRow className={classes.cellBorder} style={{backgroundColor: '#FFFFFF'}}>
                            {dimensions.slice(1).map(() => <TableCell className={classes.tableTotal}/>)}

                            <TableCell align="right" className={classes.tableTotal}>Total</TableCell>

                            <TableCell align="right" className={classes.tableTotal}>{totalEntries}</TableCell>
                        </TableRow>

                        <TableRow>
                            {
                                rows !== undefined &&
                                <Pagination
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    totalPages={totalPages}
                                    setPage={setPage}
                                    setRowsPerPage={setRowsPerPage}
                                    order={order === 'ASC' ? 'DESC' : 'ASC'}
                                    orderBy={orderBy}
                                />
                            }
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <div style={{textAlign: 'center', marginTop: '1rem'}}>
                <Button className={classes.downloadButton}
                        onClick={(_) => !data?.length || false ? undefined : exportCSV()}>
                    <img src={DownloadIcon} alt="" style={{marginRight: 5}}/>
                    Export Data
                </Button>
            </div>
        </div>
    );
};

export default HeatmapTable;
