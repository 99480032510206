import {configureStore} from '@reduxjs/toolkit';
import chartDataReducer from './slices/chartDataSlice';
import dimensonsReducer from './slices/dimensionsSlice';
import filtersReducer from './slices/filtersSlice';
import abbreviationsReducer from './slices/abbreviationsSlice';


export const store = configureStore({
  reducer: {
    chart: chartDataReducer,
    dimension: dimensonsReducer,
    filters: filtersReducer,
    abbreviations: abbreviationsReducer
  }
});

// Store state.
export type RootState = ReturnType<typeof store.getState>
// Store functionality to dispatch reducers functions.
export type AppDispatch = typeof store.dispatch;
