import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    backgroundColor: '#F0F4F6',
    height: '100%',
    minWidth: 200,
    maxWidth: 460,
    '& > div': { padding: '0 28px' },
    '& > div:last-child': { padding: 0, margin: 15 }
  },

  date: {
    color: '#324D95',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 50,
  },
}));

export default useStyles;
