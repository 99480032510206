import React from 'react';
import { Grid } from '@material-ui/core';
import Select from 'react-dropdown-select';

import useStyles from '../../style/Filters/MultipleDropdownSelector';

interface Props {
  selectTitle?: string,
  dimensionsIcon?: string,
  entries: Array<any>,
  selectedValues: Array<any>,
  setCurrentFilter?(data: Array<string>): any,
  setCurrentNames?(data: Array<string>): any,
}

/*
 * Component that renders a multiple dropdown selector.
 *
 * This is used in the filters section for selecing the values to filter.
 */
const MultipleDropdownSelector: React.FC<Props> = ({
  selectTitle,
  dimensionsIcon,
  entries,
  selectedValues,
  setCurrentFilter,
  setCurrentNames,
}) => {
  const classes = useStyles();

  /*
   * Sets cuurrent values and names as selectable elements..
   */
  const setFilters = (options: any): void => {
    if (setCurrentFilter) setCurrentFilter(options.map((option: any) => option.value));
    if (setCurrentNames) setCurrentNames(options.map((option: any) => option.name));
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Grid container justify="center">
          {
            selectTitle &&
            <Grid item className={classes.title}>
              <img src={dimensionsIcon} alt="" /> {selectTitle}:
            </Grid>
          }

          <Grid item style={{ width: '100%' }}>
            <Select
              className={classes.input}
              options={entries}
              placeholder="Select filters..."
              onChange={(values) => setFilters(values)}
              values={selectedValues}
              labelField="name"
              valueField="value"
              multi
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MultipleDropdownSelector;
