import React from 'react';

import useStyles from '../../style/FilterContainer';

import Filters from '../Filters/index';

import Logo from '../../../public/hartwig-logo.svg';

interface Props {}

/*
 * Component of the container of the filters section.
 */
const FilterContainer: React.FC<Props> = () => {
  const classes = useStyles();


  return (
    <div className={classes.container}>
      <div style={{ paddingTop: '30px' }}>
        <img src={Logo} alt="" />
      </div>

      <Filters />
    </div>
  );
};

export default FilterContainer;
