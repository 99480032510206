import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: '#21366F',
    fontSize: 15,
    fontWeight: 900,
    padding: '15px 0 20px 0',
    textAlign: 'center',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: { fontSize: 24 },
  },

  options: {
    borderBottom: '1px solid #DADADA',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
  },

  item: {
    color: '#516394',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
    marginRight: 60,
    lineHeight: 3,
    '&:last-child': { marginRight: 0 },
    [theme.breakpoints.down('sm')]: { fontSize: 12 },
  },

  dashboardContainer: {
    height: '100%',
    [theme.breakpoints.up(2000)]: { padding: '0 0 0 30%' },
    [theme.breakpoints.between(1750, 2000)]: { padding: '0 0 0 25%' },
    [theme.breakpoints.down(1750)]: { padding: '0 0 0 15%' },
    [theme.breakpoints.down('md')]: { padding: '0 0 0 10px' },
    [theme.breakpoints.down('sm')]: { padding: '0 0 0 5px' },
  },
}));

export default useStyles;
