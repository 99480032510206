import React from 'react';

import useStyles from '../../style/Table/Pagination';

import arrow from '../../../public/icon-arrow-pagination.svg';
import exportIcon from '../../../public/icon-export.png';

import {availableRowsPerPage} from '../../constants/table';
import {getDataOrdered} from '../../utils/data';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import DownloadIcon from "../../../public/download.svg";
import {Button} from "@material-ui/core";

interface Props {
    rowsPerPage: string,
    page: number,
    totalPages: number,

    setPage(data: number): void,

    setRowsPerPage(data: string): void,

    order: string,
    orderBy: string,
}

/*
 * Component that handles and renders the pagination of a table.
 */
const Pagination: React.FC<Props> = ({
                                         page,
                                         totalPages,
                                         setPage,
                                         setRowsPerPage
                                     }) => {
    const classes = useStyles();

    /*
     * Updates the quantity of elements that a page can contain.
     */
    const handleSelect = (e: any) => setRowsPerPage(e.target.value);

    /*
     * Updates when the user changes the status of a page.
     */
    const handlePageChange = (selector: string): void => {
        if (selector === 'plus')
            if (page + 1 <= totalPages - 1)
                setPage(page + 1);

        if (selector === 'minus')
            if (page - 1 >= 0)
                setPage(page - 1);
    };

    return (
        <td colSpan={1000} style={{backgroundColor: '#E5EAF9'}}>
            <div className={classes.container}>
                <div className={classes.rowSelector}>
                    Rows per page:
                    <select className={classes.select} onChange={(e) => handleSelect(e)}>
                        {availableRowsPerPage.map((row: string) => <option value={row}>{row}</option>)}
                    </select>
                </div>

                <div className={classes.rowNavigatior}>
                    <div className={classes.arrowBackground} onClick={() => handlePageChange('minus')}>
                        <img src={arrow} alt="<"/>
                    </div>

                    <div>
                        <span style={{color: '#4C4BEF'}}>{page + 1}</span>/<span>{totalPages}</span>
                    </div>

                    <div className={classes.arrowBackground} onClick={() => handlePageChange('plus')}>
                        <img src={arrow} alt=">" style={{transform: 'rotate(180deg)'}}/>
                    </div>
                </div>
            </div>
        </td>
    );
};

export default Pagination;
