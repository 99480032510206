import {  makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: { margin: '40px 45px' },

  outerTable: {
    borderRadius: 10,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  },

  table: {
    minWidth: 500,
    // Mobile like scrollbar
    '&::-webkit-scrollbar': { width: 10 },
    '&::-webkit-scrollbar-track': { backgroundColor: '#f1f1f1' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#888888' },
    '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555555' },
    '& th': {
      border: 'none',
      fontSize: 14,
      [theme.breakpoints.down('md')]: { fontSize: 12 },
      [theme.breakpoints.down('sm')]: { fontSize: 10 },
    },
  },

  // Cell Focus must be applied to the parent tag of the target cells, not to the cells themselves
  cellFocus: {
    '& > th, & > td': { backgroundColor: '#E5EAF9' },
  },

  // Cell Border must be applied to the parent tag of the target cells, not to the cells themselves
  cellBorder: {
    '& > th, & > td': {
      border: '1px solid #D9D5EC',
      borderLeft: 'none',
      padding: '0.5rem 1.25rem'
    }
  },

  cellTotal: {
    fontSize: 16,
    fontWeight: 900,
    width: 150,
    [theme.breakpoints.down('md')]: { fontSize: 14 },
    [theme.breakpoints.down('sm')]: { fontSize: 12 },
  },

  tableTotal: {
    borderLeft: 'none!important' as any,
    borderRight: 'none!important' as any,
    color: 'black',
    fontSize: 12,
    fontWeight: 900,
    textTransform: 'uppercase',
  },

  selectCircle: {
    borderRadius: '100%',
    height: 10,
    marginRight: 5,
    width: 10,
  },

  downloadButton: {
    fontSize: 13,
    color: '#263E7D',
    backgroundColor: '#F0F4F6',
    border: '0.5px solid #263E7D',
    borderRadius: 20,
    padding: 9,
    textTransform: 'none',
    alig: 'center'
  },
}));

export default useStyles;
