import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';

import { replace } from '../../../slices/chartDataSlice';
import { update } from '../../../slices/filtersSlice';

import useStyles from '../../style/Filters/index';

import { getData, parseData } from '../../utils/data';

import ConditionalElement from './ConditionalElement';
import { ActionButton } from '../General/Buttons';
import FilterSelectionRender from './FilterSelectionRender';
import Separator from './Separator';
import AlertDialog from '../General/AlertDialog';

import Edit from '../../../public/edit-icon.svg';
import Trash from '../../../public/trash-icon.svg';

import { Data, DataResponse } from '../../interfaces/data';

interface Props {}

/*
 * Component that handles and renders all the filters section.
 */
const Filters: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let filters: Array<any> = [];

  const dimensions = useSelector((state: RootState) => state.dimension.data);
  const activeFilters = useSelector((state: RootState) => state.filters.active);

  const [numberFilters, setNumberFilters] = useState<number>(1);
  const [filtered, setFiltered] = useState<boolean>(false);
  const [usedFilters, setUsedFilters] = useState<Array<any>>([]);
  const [alert, setAlert] = useState<boolean>(false);
  const [bufferFilters, setBufferFilters] = useState<any>([]);

  /*
   * Generates elements to render based on the selected filters.
   */
  const renderFilterElements = () => {
    const elements = [];

    if (bufferFilters.filter((entry: any) => entry.filter !== undefined).length === 0)
      // This is necessary for creating a copy of the saved filter data without affecting the Redux variable
      filters = JSON.parse(JSON.stringify(activeFilters));
    else
      filters = [...bufferFilters];

    for (let i = 0; i < numberFilters; i += 1) {
      if (filters[i] === undefined) filters[i] = { value: '0', filter: undefined, names: undefined };

      if (i >= 1) elements.push(<Separator message="And" />);

      elements.push(<ConditionalElement conditional={filters[i]} key={`conditionalElement-${i}`} />);
    }

    return elements;
  };

  /*
   * Fetches the data based on the selected filters/dimensions.
   *
   * TODO: this should be a thunk in a slice store.
   */
  const filter = async () => {
    const validFilters = filters.filter((entry: any) => entry.filter !== undefined);

    dispatch(update([...validFilters]));

    try {
      const data = await getData(dimensions, filters);
      const res: DataResponse = await data.json();
      const parsedData: Data = parseData(res.data, dimensions);

      dispatch(replace({ data: parsedData, total: res.total }));
      dispatch(update(filters));

      setUsedFilters(filters);
      setFiltered(true);
    } catch (error) {
       console.error(error);
    }
  };

  /*
   * Changes the state of visualization of the filters when the filters will be edited.
   */
  const editFilters = (): void => setFiltered(false);

  /*
   * Adds a new selected filter.
   */
  const addFilters = (): void => {
    editFilters();

    setBufferFilters([...filters]);
    if (numberFilters + 1 <= 4) setNumberFilters(numberFilters + 1);
  };

  /*
   * Removes all the selected filters.
   *
   * TODO: this should be refactorized but the fetching data should be reutilized and kept as a thunk in a slice store.
   */
  const deleteOperation = async () => {
    try {
      const data = await getData(dimensions, []);
      const res: DataResponse = await data.json();
      const parsedData: Data = parseData(res.data, dimensions);

      dispatch(replace({ data: parsedData, total: res.total }));
      dispatch(update([]));

      setFiltered(false);
      setAlert(false);
      setNumberFilters(1);
      setBufferFilters([]);
    } catch (error) {
      console.error(`There was an error while trying to delete the filters: ${error}`);
    }
  };

  /*
   * Updates status of the removal of all the filters.
   */
  const cancelOperation = () => setAlert(false);

  /*
   * Opens the delete filters dialog.
   */
  const deleteFilters = () => {
    setBufferFilters([...filters])
    setAlert(true);
  };

  return (
    <div className={classes.container}>
      {
        alert &&
        <AlertDialog
          title="Delete filters?"
          acceptMessage="Delete"
          rejectMessage="Cancel"
          acceptDeco="arrow"
          onAccept={deleteOperation}
          onReject={cancelOperation}
        />}

      <div className={classes.header}>
        <div className={classes.title}>Filters</div>

        <div className={classes.iconsContainer}>
          {
            filtered &&
            <div className={classes.icon} style={{ marginRight: '20px' }} onClick={() => editFilters()}>
              <img src={Edit} alt="" />
            </div>
          }

          <div className={classes.icon} onClick={() => deleteFilters()}>
            <img src={Trash} alt="" />
          </div>
        </div>
      </div>

      <div className={classes.filterContainer}>
        {
          filtered ?
          <FilterSelectionRender filters={usedFilters} />
          :
          renderFilterElements()
        }
      </div>

      <div className={classes.buttonContainer}>
        <div className={classes.add} onClick={() => addFilters()}>+ Add new filter</div>
        <ActionButton
          message="Apply"
          handleClick={filter}
          disabled={filtered}
        />
      </div>
    </div>
  );
};

export default Filters;
