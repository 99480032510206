import React from 'react';

import useStyles from '../../style/Filters/Separator';

interface Props { message: string }

/*
 * Component of a seprator between added filters.
 */
const Separator: React.FC<Props> = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <hr className={classes.border} />
      <div className={classes.message}>{message}</div>
    </div>
  );
};

export default Separator;
