import React from 'react';

import { useActionStyles } from '../../style/General/Buttons';

interface ActionProps {
  message: string,
  handleClick(): void,
  disabled: boolean,
}

/*
 * Component of a generic button with the required styles.
 */
export const ActionButton: React.FC<ActionProps> = ({ message, handleClick, disabled }) => {
  const classes = useActionStyles();

  return (
    <div className={classes.container}>
      <button
        className={classes.button}
        onClick={handleClick}
        disabled={disabled}
        style={disabled ? { backgroundColor: '#314C9459' } : {}}
      >{message}</button>
    </div>
  );
};
