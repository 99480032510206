import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 15,
    [theme.breakpoints.down('sm')]: { fontSize: 9 },
  },

  exportIcon: {
    paddingRight: 5,
  },
  
  export: {
    alignItems: 'left',
    display: 'flex',
    fontWeight: 600,
    paddingRight: 15,
  },

  rowSelector: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 600,
    paddingRight: 15,
  },

  select: {
    backgroundColor: 'inherit',
    border: 'none',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '&:focus-visible': { outline: 'none' },
    '& > option': { fontSize: 14 },
  },

  rowNavigatior: {
    display: 'flex',
    fontWeight: 900,
    paddingRight: 40,
    '& > div': { margin: '0 2px' },
  },

  arrowBackground: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    height: 13,
    justifyContent: 'center',
    width: 13,
  },
}));

export default useStyles;
