import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Dimension} from '../app/interfaces/Dimension';

export interface DimensionsState {
    data: Array<Dimension>
    dimensions: Array<Dimension>
}

/*
 * Initial state of the slice.
 *
 * It should always have at least one dimension to fetch the data and render the chart.
 */
const initialState: DimensionsState = {
    data: [{name: 'Primary Tumor Location', value: 'primaryTumorLocation'},
        {name: 'Treatment Type', value: 'treatmentType'}],
    dimensions: []
};

export const fetchAllDimensions = createAsyncThunk('dimensions/load', async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dimensions`);
        return await response.json()
    } catch (error) {
        console.error('Error while fetching the dimensions', error);
    }
})
/*
 * Initial config of the slice.
 *
 * It just includes a single reducer that updates the current selected dimensions.
 */
export const dimensionsSlice = createSlice({
    name: 'queryString',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Array<Dimension>>) => {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchAllDimensions.fulfilled, (state, action) => {
            // Add user to the state array
            for (const dimension of action.payload) {
                state.dimensions.push({name: dimension.description, value: dimension.name})
            }
        })
    },
});

/*
 * Updates the current selected dimensions.
 */
export const {update} = dimensionsSlice.actions;

/*
 * Reducers of the slice.
 */
export default dimensionsSlice.reducer;
