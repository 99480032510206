import React from 'react';

import useStyles from '../../style/Filters/FilterSelectionRender';

interface Props { filters: Array<any> }

/*
 * Component that renders the selected filters when applied.
 */
const FilterSelectionRender: React.FC<Props> = ({ filters }) => {
  const classes = useStyles();

  /*
   * Reduces the size of a string in order to use the minimum space.
   */
  const stylizeTag = (string: string): string => {
    if (string.length > 10) return `${string.substring(0, 6)}...`;

    return string;
  };

  const renderElement = () => {
    return filters.map((element: any) => (
      Array.isArray(element.names) ?
      element.names.map((subElement: any) => <div className={classes.element}>{stylizeTag(subElement)}</div>)
      :
      <div className={classes.element}>{stylizeTag(element.names)}</div>
    ))
  };

  return <div className={classes.container}>{renderElement()}</div>;
};

export default FilterSelectionRender;
