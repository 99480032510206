import {Data, NestedData, NestedDataResponse} from '../interfaces/data';
import {Dimension} from '../interfaces/Dimension';

const messageBuilder = (dimensions: Dimension[], filters: any[], asCSV: boolean = false) => {
    const dimensionsQuery = dimensions.map(c => `dimensions[]=${c.value}`).join('&');
    const filtersQuery = filters.map((f, i) => {
        const filterValues = f.filter
            .map((fv: string) => `&filters[${i}][1]=${fv}`).join('');
        return `filters[${i}][0]=${f.value}${filterValues}`;
    }).join('&');

    let message = `${process.env.REACT_APP_API_URL}/query/${asCSV ? 'csv/' : ''}?${dimensionsQuery}${filtersQuery ? `&${filtersQuery}` : ''}`;

    return message;
};

export const getData = (dimensions: Dimension[], filters: any[], asCSV: boolean = false): Promise<Response> => {
    const message = messageBuilder(dimensions, filters, asCSV);

    return fetch(message);
};

export const getDataOrdered = (
    dimensions: Dimension[],
    filters: any[],
    orderBy: string,
    order: string,
    size: string,
    page: number = 1,
    asCSV: boolean = false
) => {
    let message = messageBuilder(dimensions, filters, asCSV);

    message = `${message}&orderBy=${orderBy}&order=${order}`;

    if (!asCSV) {
        message = `${message}&page=${page}&size=${size}`;
    }

    return fetch(message);
};

export const parseData = (data: NestedDataResponse, dimensions: Dimension[]): Data => {
    const dataEntries = Object.entries(data);
    const {name} = dimensions[0];

    return dataEntries.reduce((prev: Data, curr) => {
        const [key, value] = curr;
        let children;

        if (typeof value != 'number') children = parseData(value, dimensions).children;

        const dataToAdd = children ? ({name: key, children} as Data) : ({name: key, value} as NestedData);

        prev.children.push(dataToAdd);

        return prev;
    }, {name, children: []});
};
