import React, {useEffect, useState, useCallback} from 'react';
import {Button, Grid, Link} from '@material-ui/core';

import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../store';

import {replace} from '../../slices/chartDataSlice';
import {update} from '../../slices/abbreviationsSlice';

import useStyles from '../style/Chart';

import CardLegend from './CardLegend';
import SunburstChart from './SunburstChart';

import {getData, parseData} from '../utils/data';

import {SelectedDimension} from '../interfaces/Dimension';
import {Data, DataResponse} from '../interfaces/data';

import DownloadIcon from '../../public/download.svg';
import RequestAccessIcon from '../../public/lock-unlocked-16.png';
import KWFLogo from '../../public/kwf-logo-rgb.png';

interface Props {
}

/*
 * Component that renders the chart section. This includes the card, the sunburst chart and the dimension selectors.
 *
 * This component shares data between these three elements to represent state status.
 */
const Chart: React.FC<Props> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state: RootState) => state.chart.data);
    const totalData = useSelector((state: RootState) => state.chart.total);
    const dimensionsToSearch = useSelector((state: RootState) => state.dimension.data);
    const filtersToSearch = useSelector((state: RootState) => state.filters.active);

    // Here selected dimensions is the hovered dimension in the chart.
    const [selectedDimensions, setSelectedDimensions] = useState<SelectedDimension[]>([]);
    // Flag for indicating what kind of information the card should display based on the result of the dimensions that the user choose.
    const [mainInfo, setMainInfo] = useState<boolean>(true);

    /*
     * Fetches the data based on the dimensions that the user choose.
     *
     * TODO: move this function into a thunk like function in a store slice.
     */
    const getDimensions = useCallback(async (): Promise<void> => {
        try {
            const data = await getData(dimensionsToSearch, filtersToSearch);
            const res: DataResponse = await data.json();
            const parsedData: Data = parseData(res.data, dimensionsToSearch);

            dispatch(replace({data: parsedData, total: res.total}));
            dispatch(update(res.abbreviations))
            setSelectedDimensions([]);
            setMainInfo(true);
        } catch (error) {
            console.error(error);
        }
    }, [dimensionsToSearch, dispatch, filtersToSearch]);

    /*
     * Checks if the chosen dimensions contains any empty dimension.
     *
     * This empty dimension happens when the user adds a new dimension but no defined dimension has been chosen.
     */
    const emptyConditionExists = useCallback((): boolean => {
        return dimensionsToSearch.filter(c => c.value === '' && c.name === '').length > 0 ? true : false;
    }, [dimensionsToSearch]);

    useEffect(() => {
        // If there is no empty chosen dimensions then fetch the data.
        if (!emptyConditionExists()) getDimensions();
    }, [emptyConditionExists, getDimensions]);

    useEffect(() => {
        // If there are no selected dimensions then there is no "main" info to show.
        if (mainInfo && selectedDimensions.length) setMainInfo(false);
    }, [selectedDimensions]);

    /*
     * Fetches and downloads the CSV report of the chosen dimensions.
     *
     * TODO: move this function into a thunk like function in a store slice.
     */
    const exportCSV = async () => {
        const res = await getData(dimensionsToSearch, filtersToSearch, true);
        const blob = await res.blob();

        const file = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = file;
        a.setAttribute('download', 'table.csv');
        a.click();
        URL.revokeObjectURL(file);
    }

    return (
        <div className={classes.container}>
            <Grid container direction="row" wrap="nowrap">
                <Grid xs={8} item style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
                    <SunburstChart totalPatients={totalData} data={data} onDimensionSelected={setSelectedDimensions}/>
                </Grid>

                <Grid xs={4} item className={classes.cardLegend}>
                    <div style={{textAlign: 'center', marginBottom: '1rem'}}>
                        Developed with support from <img src={KWFLogo} alt=""
                                                         style={{marginRight: 5, width: "15%", height: "15%"}}/>
                    </div>
                    <CardLegend selectedDimensions={selectedDimensions} isMainInfo={mainInfo}/>
                    <div style={{textAlign: 'center', marginTop: '1rem'}}>
                        <Button className={classes.downloadButton}
                                onClick={(_) => window.open(" https://www.hartwigmedicalfoundation.nl/en/data/data-acces-request/", "_blank")}
                                disabled={!data?.children?.length || false}>
                            <img src={RequestAccessIcon} alt="" style={{marginRight: 5}}/>
                            Request Access
                        </Button>
                        <Button className={classes.downloadButton}
                                onClick={(_) => exportCSV()} disabled={!data?.children?.length || false}>
                            <img src={DownloadIcon} alt="" style={{marginRight: 5}}/>
                            Export Data
                        </Button>
                    </div>
                    <div style={{textAlign: 'center', marginTop: '1rem'}}>
                        <a href="https://www.hartwigmedicalfoundation.nl/wp-content/uploads/2022/04/Privacy-PolicyEN.pdf" target="_blank">Privacy
                            Policy</a> | <a href="https://www.hartwigmedicalfoundation.nl/wp-content/uploads/2022/03/Disclaimer-hartwigmedicalfoundation.nl-EN-1.pdf"
                                            target="_blank">Disclaimer</a> | <a
                        href="mailto:ict@hartwigmedicalfoundation.nl" target="_blank">Contact Us</a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Chart;
