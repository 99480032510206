import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import ReactGA from 'react-ga4';

import Layout from './app/components/Layout/index';
import {fetchAllFilters, fetchFilterCatalogs} from './slices/filtersSlice';
import {fetchAllDimensions} from "./slices/dimensionsSlice";

interface Props {
}

/*
 * Main app wrapper.
 */
const App: React.FC<Props> = () => {

    const MEASUREMENT_ID = "G-1MQG37RJD1";
    ReactGA.initialize(MEASUREMENT_ID);

    const dispatch = useDispatch();

    // Fetching the filter catalogs and dimensions the first time the app is loaded.
    useEffect(() => {
        dispatch(fetchAllDimensions())
        dispatch(fetchAllFilters())
        dispatch(fetchFilterCatalogs())
    }, [dispatch]);

    return <Layout/>
}

export default App;
