import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    borderBottom: '0.5px solid #4973E1',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '0 20px 30px 20px',
  },

  element: {
    backgroundColor: '#263E7D1F',
    border: 'none',
    borderRadius: 20,
    color: '#21366EC4',
    fontSize: 13,
    fontWeight: 700,
    padding: '10px 20px',
    textAlign: 'center',
    width: 105,
    '&:nth-child(2)~div': { marginTop: 15 },
  },
}));

export default useStyles;
