import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ChartDataState { data: any, total: number }

/*
 * Initial state of slice.
 *
 * This should be updated whenever it fetch the data with the latest constraints.
 *
 * `data` is just the data that feeds the chart. Initally there is no data because we need to fetch it first.
 * `total` is the total amount of data counted by the `value` column, you can check out that in the backend. By default is 0 (no data).
 */
const initialState: ChartDataState = { data: {}, total: 0 };

/*
 * Initial config of slice.
 *
 * It includes a single reducer where it updated the current chart data based on the fetched data with the latest constraints.
 */
export const chartDataSlice = createSlice({
  name: 'chartData',
  initialState,
  reducers: {
    replace: (state, action: PayloadAction<ChartDataState>) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
  },
});

/*
 * Updates the current chart data.
 */
export const { replace } = chartDataSlice.actions;

/*
 * Reducers of the slice.
 */
export default chartDataSlice.reducer;
