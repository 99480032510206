import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    fontSize: 16,
    marginLeft: 15,
    '& img': { cursor: 'pointer' },
    [theme.breakpoints.down('md')]: { fontSize: 14 },
  },

  arrowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginLeft: 10,
  },

  upperArrow: { transform: 'rotate(180deg)' },
}));

export default useStyles;
