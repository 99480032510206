import React, {MouseEvent, useEffect, useRef, useState} from 'react';

import {SelectedDimension} from '../interfaces/Dimension';
import {Data, NestedData} from '../interfaces/data';

import {setHighOpacity, setLowOpacity, createChart, partition} from '../utils/d3';
import {HierarchyRectangularNode} from 'd3';

import NotFound from '../../public/not-found.png';

interface Props {
    totalPatients: number;
    data: Data;
    onDimensionSelected: (d: SelectedDimension[]) => void;
}

/*
 * Component that renders the sunburst chart given the needed data.
 */
const SunburstChart: React.FC<Props> = ({totalPatients, data, onDimensionSelected}) => {
    // In order to render the chart with D3 a reference to the parent tag is needed.
    const [nodeRef, setNodeRef] = useState<HTMLDivElement | null>(null);
    // Checks if the parent tag where the chart will be append has been rendered.
    const [nodeRefLoaded, setNodeRefLoaded] = useState<boolean>(false);

    // The chart isn't part of the dynamic state of react, so we need to make actual references in any event of the chart.
    const [elementSelected, _setElementSelected] = useState<HierarchyRectangularNode<NestedData>[]>([]);
    const elementSelectedRef = useRef(elementSelected);

    const setElementSelected = (data: HierarchyRectangularNode<NestedData>[]) => {
        elementSelectedRef.current = data;
        _setElementSelected(data);
    };

    useEffect(() => {
        if (nodeRef && !nodeRefLoaded) {
            createChart(nodeRef, selectDimension, root, totalPatients);
            setNodeRefLoaded(true);
        }
    }, [nodeRef, data]);

    useEffect(() => {
        createChart(nodeRef!, selectDimension, root, totalPatients);
    }, [data])

    const root = partition(data);

    /*
     * Selects the dimension and its ancestors when the user has hovered a slice.
     */
    function selectDimension(_: MouseEvent, i: HierarchyRectangularNode<NestedData>) {
        const dimensions = elementSelectedRef.current;

        let reversedAncestors = i.ancestors().reverse();
        reversedAncestors.shift();

        const descendants = i.descendants();

        const dimensionsToUpdate = i.depth > 1 ? [i, ...reversedAncestors, ...descendants, ...dimensions] : [i, ...reversedAncestors, ...descendants];

        setElementSelected(dimensionsToUpdate)

        const selectedDimensions: SelectedDimension[] = reversedAncestors.map((d: any) => {
            return {value: d.value, depth: d.depth, name: d.data.name}
        }).sort((a: SelectedDimension, b: SelectedDimension) => a.depth - b.depth);

        onDimensionSelected(selectedDimensions);

        setLowOpacity(nodeRef!);
        setHighOpacity(nodeRef!, reversedAncestors);
    }

    /*
     * Checks if the data that feeds the chart is empty.
     */
    function isDataEmpty(): boolean {
        return !(data?.children || []).length;
    }

    return <>
        <div ref={setNodeRef} style={{width: '100%', display: isDataEmpty() ? 'none' : 'unset'}}/>
        {
            isDataEmpty() && <img src={NotFound} alt=""/>
        }
    </>;
};

export default SunburstChart;
