import {createStyles, Grid, Link, makeStyles, Paper, Theme, Typography} from "@material-ui/core";

import {loadDepthIcon, setDepthColour} from '../utils/depth';

import BackgroundImg from '../../public/card_background.png';

import {SelectedDimension} from '../interfaces/Dimension';
import {useSelector} from "react-redux";
import {RootState} from "../../store";

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 280,
            minWidth: 180,
            height: 470,
            borderRadius: 20,
            padding: 20,
            margin: 'auto',
            backgroundImage: `url(${BackgroundImg})`,
            backgroundPositionX: '100%',
            backgroundPositionY: '100%',
            backgroundRepeat: 'no-repeat',
        },
        title: {
            textAlign: 'center',
            color: '#1d2d59',
            fontWeight: 700,
        },
        content: {
            height: '100%',
        },
        separator: {
            top: 0,
            position: 'relative',
            width: '50%',
            border: 'none',
            height: 1,
            color: 'rgba(28, 45, 89, 0.43)',
            backgroundColor: 'rgba(28, 45, 89, 0.43)',
            marginBottom: '0.75rem'
        },
        bottom: {
            marginTop: 'auto',
            color: '#1C2D59',
        },
        backgroundImage: {
            position: 'relative',
            bottom: '60%',
            right: '-63.5%'
        },
        depthIcon: {
            width: '10%',
            marginTop: 8
        },
        dataName: {
            wordBreak: 'break-word',
            fontWeight: 700
        },
        dimensionName: {
            fontSize: 13,
            wordBreak: 'break-word',
            fontWeight: 700
        },
        dataText: {
            fontWeight: 700,
        },
    })
);

interface Props {
    selectedDimensions: SelectedDimension[];
    isMainInfo?: boolean;
}

/*
 * Component that represent the selected (hovered) dimensions and its values. 
 * It also displays some information when there is no dimension selected (hovered) or the data is empty.
 */
const CardLegend: React.FC<Props> = ({selectedDimensions, isMainInfo = true}) => {
    const classes = useStyles();

    const data = useSelector((state: RootState) => state.chart.data);
    const abbreviations = useSelector((state: RootState) => state.abbreviations.data);
    const dimensions = useSelector((state: RootState) => state.dimension.data);

    /*
     * Format name when an abbreviation exists or the value of the dimension is N/A.
     */
    function formatName(dimensionName: string): string {
        const isAbbreviation = abbreviations[dimensionName];
        return dimensionName === 'N/A' ? 'Not available' : isAbbreviation ? isAbbreviation : dimensionName;
    }

    /*
     * Checks if there is no data.
     */
    function isDataEmpty(): boolean {
        return !(data?.children || []).length;
    }

    return (
        <Paper elevation={3} className={classes.root}>
            <Grid container direction="column" className={classes.content} wrap="nowrap">
                {
                    isMainInfo && !isDataEmpty() && !selectedDimensions.length &&
                    <Grid item className={classes.dataText}>
                        <p>Start by hovering over the sunburst chart.</p>
                        <p>The results will be shown on this panel.</p>
                    </Grid>
                }

                {
                    isDataEmpty() &&
                    <Grid item className={classes.dataText}>
                        <p>No results were obtained with your conditions, please try a different combination of filters
                            and dimensions.</p>
                    </Grid>
                }

                {!isMainInfo && !isDataEmpty() && selectedDimensions.length
                    && selectedDimensions.map((d, i) =>
                        <Grid item style={{color: setDepthColour(d.depth), marginBottom: 7.5}} key={`cardLegend-${i}`}>
                            <Grid container direction="row" wrap="nowrap">
                                <Grid item className={classes.depthIcon}>
                                    <img src={loadDepthIcon(d.depth)} alt=""/>
                                </Grid>
                                <Grid item>
                                    <Typography component="h5" variant="h5"
                                                className={classes.dataText}>{d.value} patients</Typography>
                                    <Typography className={classes.dataName}>{formatName(d?.name)}</Typography>
                                    <Typography
                                        className={classes.dimensionName}>{dimensions[d.depth - 1]?.name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </Paper>
    )
}

export default CardLegend; 
