import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '25px 0',
    position: 'relative',
  },

  border: {
    borderColor: '#314C94',
    borderTop: 'none',
  },

  message: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #314C94',
    borderRadius: 10,
    color: '#314C94',
    fontSize: 9,
    fontWeight: 700,
    left: 105,
    padding: '4px 12px',
    position: 'absolute',
    textTransform: 'uppercase',
    top: -10,
  },
}));

export default useStyles;
