import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {RootState} from '../../../store';

import DropdownSelector from './DropdownSelector';
import MultipleDropdownSelector from './MultipleDropdownSelector';
import {FiltersState} from "../../../slices/filtersSlice";

interface Props {
    conditional: any
}

/*
 * Component that renders the selected filters.
 *
 * This includes two elements, the selection of a filter and the multiple selection of the values to filter.
 */
const ConditionalElements: React.FC<Props> = ({conditional}) => {
    const available = useSelector((state: RootState) => state.filters.available);
    const options = useSelector((state: RootState) => state.filters);

    const [currentConditional, setCurrentConditional] = useState<string>(conditional.value);
    const [currentFilter, setCurrentFilter] = useState<string | Array<string> | undefined>(conditional.filter);
    const [currentNames, setCurrentNames] = useState<string | Array<string> | undefined>(conditional.names)
    const [currentOptions, setCurrentOptions] = useState<Array<any> | undefined>(undefined);

    // Sets the selected filters and the selectable elements names/values.
    useEffect(() => {
        setCurrentConditional(conditional.value);
        setCurrentFilter(conditional.filter);
        setCurrentNames(conditional.names);
    }, [conditional]);

    // Handles each one of the possible filters.
    useEffect(() => {
        setCurrentOptions(options[currentConditional]);
        conditional.value = currentConditional;
        conditional.filter = currentFilter;
        conditional.names = currentNames;
    }, [
        currentConditional,
        conditional,
        currentFilter,
        currentNames
    ]);

    /*
     * Handles yet not selected filters.
     */
    const defaultValue = (array: Array<any>, filter: any): string => {
        const value = array.find((option) => option.value === filter);

        if (value) return value.name;

        return '';
    };

    /*
     * Handles yet not selected values to filter.
     */
    const multiDefaultValue = (array: Array<any>, filters: any): Array<any> => {
        if (!filters) return [];

        const values = array.filter((option) => filters.includes(option.value));

        if (values) return values;

        return [];
    };

    return (
        <div style={{padding: '0 25px 0 25px'}}>
            <DropdownSelector
                entries={available}
                setCurrentConditional={setCurrentConditional}
                setCurrentNames={setCurrentNames}
                selectedValues={defaultValue(available, currentConditional)}
            />

            {
                (currentConditional !== 'none' &&
                    currentOptions !== undefined) &&
                <MultipleDropdownSelector
                    entries={currentOptions}
                    setCurrentFilter={setCurrentFilter}
                    setCurrentNames={setCurrentNames}
                    selectedValues={multiDefaultValue(currentOptions, currentFilter)}
                />
            }
        </div>
    );
};

export default ConditionalElements;
