import React, { useState } from 'react';

import useStyles from '../../style/General/AlertDialog';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import arrowRight from '../../../public/arrow-right-icon.svg';

interface Props {
  title: string,
  acceptMessage?: string,
  rejectMessage?: string,
  acceptDeco?: string,
  onAccept(): any,
  onReject(): any,
}

/*
 * Component of a generic alert dialog.
 *
 * Used when the user deletes all the filters. This can be re-used.
 */
const AlertDialog: React.FC<Props> = ({
  title,
  acceptMessage,
  rejectMessage,
  acceptDeco,
  onAccept,
  onReject,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);

    onReject();
  };

  const handleAccept = () => {
    setOpen(false);

    onAccept();
  };

  const decoration = () => {
    if (!acceptDeco) return '';

    switch(acceptDeco) {
      case 'arrow':
        return arrowRight;
      default:
        return '';
    }
  };

  return (
    <div>
      <Dialog
        className={classes.container}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.closeButtonContainer}>
          <span className={classes.closeButton} onClick={handleClose}>x</span>
        </div>

        <DialogTitle className={classes.title}>{title}</DialogTitle>

        <DialogActions className={classes.generalButtons}>
          <Button
            className={classes.rejectButton}
            onClick={handleClose}
            color="primary"
          >
            {rejectMessage || 'Reject'}
          </Button>

          <Button
            className={classes.acceptButton}
            onClick={handleAccept}
            color="primary"
            autoFocus
          >
            {acceptMessage || 'Accept'}

            {
              acceptDeco &&
              <img
                className={classes.image}
                src={decoration()}
                alt=""
              />
            }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
